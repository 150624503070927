import React from 'react';
import icon from './assets/icon.png';
import textLogo from './assets/text-logo.png';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={icon} className='App-logo' alt='icon' />
        <img src={textLogo} className='text-logo' alt='text logo' />
        <p className='App-text spacing'>🚧 Under construction 🚧</p>
      </header>
    </div>
  );
}

export default App;
